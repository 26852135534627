import React, { useEffect, useState } from "react";
import { Table, Button, Switch } from "antd";
import { del, get, post } from "../../api/axios";
import { Link } from "react-router-dom";
import { message, Popconfirm } from "antd";
import { Pagination } from "antd";
import { checkPermission } from "../auth/ProtectedRoutes";
import SearchBar from "../../utils/SearchBar";

const Banks = () => {
  const columns = [
    {
      title: "رقم البنك",
      dataIndex: "id",
      render: status => <span>{status}</span>,
    },
    {
      title: "الاسم بالعربي",
      dataIndex: "name_ar",
    },
    {
      title: "الاسم بالانجليزي",
      dataIndex: "name_en",
    },
    {
      title: "الحالة",
      dataIndex: "id",
      render: (id, { status }) => (
        // <
        //     title="تغير الحالة"
        //     description="هل أنت متأكد من تغير الحالة؟"
        //     // onConfirm={() => confirm(id)}
        //     onCancel={cancel}
        //     okText="نعم"
        //     cancelText="لا"
        // >
        <>
          <Switch
            onChange={() => handleChangeStatus(id, status)}
            checked={status}
            style={{
              margin: 16,
            }}
          />
        </>
      ),
    },
  ];

  if (checkPermission("update-bank")) {
    columns.push({
      title: "تعديل",
      dataIndex: "id",
      render: id => (
        <button className="btn btn-outline-warning btn-sm p-0">
          <Link
            className="color-inherit text-decoration-none py-1 px-2 d-block"
            to={`/banks/edit/${id}`}
          >
            تعديل
          </Link>
        </button>
      ),
    });
  }
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
   const [debouncedValue, setDebouncedValue] = useState(searchQuery);
  const [total, setTotal] = useState(1);

  const handleChangeStatus = (id, status) => {
    post(`change-bank-status/${id}&`, { status: !status });
    message.success("تم تغير الحالة");

    const newData = [];
    data.map(item => {
      if (item.id == id) {
        newData.push({ ...item, status: !item["status"] });
      } else {
        newData.push(item);
      }
    });
    setData(newData);
  };

  useEffect(() => {
    get(`banks?page=1&search=${searchQuery}`).then(res => {
      setData(res.data.data);
      setTotal(res.data.meta.last_page);
      setLoading(false);
    });
  }, [debouncedValue]);

  const handleDelet = id => {
    del(`banks/${id}`);
    let dateFilter = data.filter(item => item.id !== id);
    setData(dateFilter);
  };

  

  
  
  const handlePagination = pageNumber => {
    setLoading(true);

    get(`banks?page=${pageNumber}`).then(res => {
      setData(res.data.data);
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="row">
      
        {checkPermission("create-bank") && (
          <div className="col-lg-4 offset-lg-4 mb-3">
            <Link
                className="text-decoration-none d-block h-100"
                to="/banks/add"
              >
              <Button type="primary" block>
              {" "}
                إضافة{" "}
            </Button>
            </Link>
          </div>
        )}
        <SearchBar
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          debouncedValue={debouncedValue}
          setDebouncedValue={setDebouncedValue}
        />
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              scroll={{
                y: "65vh",
                x: 650,
              }}
               columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
            {/* <div className='text-center mt-3 btn-delete-table'>
              <Button type="primary" danger  size='large' >
                حذف
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banks;
