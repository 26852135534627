import React, { useEffect, useState } from 'react'
import { Input, Typography, Button, message, Select, Form, } from 'antd';
import { get, post, postFromData } from '../../api/axios';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const AddRole = () => {
    const navigate = useNavigate();

    const [inputsValue, setinputsValue] = useState(
        {
            "name_ar": '',
            "name": "",
            "permissions": "",
        }
    );
    const [dataPermissions, setDataPermissions] = useState([]);
    const [optionPermissions, setOptionPermissions] = useState([]);

    useEffect(() => {
        setOptionPermissions(dataPermissions.map(d => ({
            "value": d.value,
            "label": d.display_name,
        })))

    }, [dataPermissions]);

    useEffect(() => {
        get('get-all-permissions').then(res => {
            setDataPermissions(res.data)
        })
    }, []);

    const onChange = (value) => {
        console.log(`selected ${value}`);
        setinputsValue({ ...inputsValue, permissions: value })
    };
    const onSearch = (value) => {
        console.log('search:', value);
    };




    const handleChange = (e) => {
        setinputsValue({ ...inputsValue, [e.target.id]: e.target.value })

    }

    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true);

        post('roles', inputsValue).then(res => {
            message.success('تم الإضافة بنجاح');
            setLoading(false);
            navigate('/roles')
        }).catch(err => {
            message.error(err.response.data.message);
            console.log('err.errors', err.response.data.errors);
            setLoading(false);
        })

    };

    return (
        <div>
            <Title level={2} className='text-main mb-4'> إضافة دور جديدة </Title>
            <Form
                onFinish={onFinish}
                labelCol={{
                    span: 7,
                }}
                wrapperCol={{
                    span: 15,
                }}

                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <div className='row'>

                    <div className='col-lg-6'>

                        <Form.Item
                            label='الاسم بالعربي'
                            name='name_ar'
                            onChange={handleChange}
                            rules={[
                                {
                                    required: true,
                                    message: 'هذا الحقل مطلوب!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </div>
                    <div className='col-lg-6'>

                        <Form.Item
                            label='الاسم بالانجليزي'
                            name='name'
                            onChange={handleChange}
                            rules={[
                                {
                                    required: true,
                                    message: 'هذا الحقل مطلوب!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </div>

                    <div className='col-lg-8'>
                        <Form.Item
                            label='الأذونات'
                            name='permissions'
                            onChange={handleChange}
                            rules={[
                                {
                                    required: true,
                                    message: 'هذا الحقل مطلوب!',
                                },
                            ]}
                        >
                            <Select
                                mode="multiple"
                                className='w-100'
                                showSearch
                                placeholder="حدد الأذونات"
                                optionFilterProp="children"
                                onChange={onChange}
                                onSearch={onSearch}
                                filterOption={(input, option) =>
                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                }
                                options={optionPermissions}
                            />

                        </Form.Item>
                    </div>

                    <div className='col-lg-4 offset-lg-4'>

                        <Form.Item
                            wrapperCol={{
                                offset: 1,
                                span: 16,
                            }}
                        >
                            <Button
                                loading={loading}
                                size='large' block type="primary" htmlType="submit">
                                {loading ? 'جاري الإضافة...' : 'إضافة'}
                            </Button>
                        </Form.Item>
                    </div>
                </div>



            </Form>
        </div>
    )
}

export default AddRole
