import React, {useEffect, useRef, useState} from "react";
import {Button, Checkbox, Form, Input, message, Radio, Select, Spin, Typography,} from "antd";
import {Link, useNavigate} from "react-router-dom";
import {useParams} from "react-router";
import {get, postFromData} from "../../../../api/axios";
import Steper from "../layoutProperty/Steper";
import { GoogleMap, Marker, LoadScript, Autocomplete } from "@react-google-maps/api";
const { Title } = Typography;



const Step2 = () => {

  
  const navigate = useNavigate();
  let { districtId } = useParams();
  const { TextArea } = Input;

  const [fromloading, setFromLoading] = useState(false);

  const [inputsValue, setinputsValue] = useState({
    code: "",
    type: "",
    name: "",
    description: "",
    property_number: "",
    property_aria: "",
    guests_type: "",
    suitable_guests: "",
    rent_type: "",
    region_id: "",
    city_id: "",
    district_id: "",
    address_map: "",
    twitter_link: "",
    instagram_link: "",
    pinterest_link: "",
  });
  const handleChange = e => {
    setinputsValue({ ...inputsValue, [e.target.id]: e.target.value });
  };

  const [dataCities, setDataCities] = useState([]);
  const [dataguests_type, setDataguests_type] = useState([]);
  const [radioguests_type, setradioguests_type] = useState([]);
  const [rentTypes, setRentTypes] = useState([]);
  const [optionsSuitableGuests, setOptionsSuitableGuests] = useState([]);
  const [optionsSuitableGuestsData, setOptionsSuitableGuestsData] = useState([]);
  const [optionsregion_id, setOptionsregion_id] = useState([]);
  const [optionsregion_idData, setOptionsregion_idData] = useState([]);
  const [optionscity_id, setOptionscity_id] = useState([]);
  const [optionscity_idData, setOptionscity_idData] = useState([]);
  const [optionsdistrict_id, setOptionsdistrict_id] = useState([]);
  const [optionsdistrict_idData, setOptionsdistrict_idData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [propertyTypes, setPropertyTypes] = useState([]);

  useEffect(() => {
    setradioguests_type(
      dataguests_type.map(d => ({
        value: d.id,
        label: d.name,
      }))
    );
  }, [dataguests_type]);
  useEffect(() => {
    setOptionsSuitableGuestsData(
      optionsSuitableGuests.map(d => ({
        value: d.id.toString(),
        label: d.name,
      }))
    );
  }, [optionsSuitableGuests]);
  useEffect(() => {
    setOptionsregion_idData(
      optionsregion_id.map(d => ({
        value: d.id,
        label: d.name_ar,
      }))
    );
  }, [optionsregion_id]);
  useEffect(() => {
    setOptionscity_idData(
      optionscity_id.map(d => ({
        value: d.id,
        label: d.name_ar,
      }))
    );
  }, [optionscity_id]);
  useEffect(() => {
    setOptionsdistrict_idData(
      optionsdistrict_id.map(d => ({
        value: d.id,
        label: d.name_ar,
      }))
    );
  }, [optionsdistrict_id]);
  useEffect(() => {
    if (localStorage.getItem("codeAddProperty")) {
      setinputsValue({ ...inputsValue, code: localStorage.getItem("codeAddProperty") });
      get("get-suitable-guests").then(res => {
        setFromLoading(true);
        setOptionsSuitableGuests(res.data);
      });
      get("get-guest-types").then(res => {
        setFromLoading(true);
        setDataguests_type(res.data);
      });
      get("get-rent-types").then(res => {
        setFromLoading(true);
        setRentTypes(res.data);
      });
      get("get-all-property-types").then(res => {
        setFromLoading(true);
        let types = [];
        res.data.map(function (item) {
          types= [
            ...types,
            { value: item.id, label: item.name_ar },
          ];
        });
        setPropertyTypes(types);
      });
      get("get-all-regions").then(res => {
        setFromLoading(true);
        setOptionsregion_id(res.data);
      });
    } else {
      navigate("/add-property/step-1");
    }
  }, []);

  const onFinish = values => {
    setLoading(true);
  
     const updatedInputs = {
      ...inputsValue,
      latitude: markerPosition.lat,  
      longitude: markerPosition.lng,  
    };
  
    postFromData("add-property/step-2", updatedInputs)
      .then(res => {
        setLoading(false);
        navigate("/add-property/step-3");
      })
      .catch(err => {
        message.error(err.response.data.message);
        setLoading(false);
      });
  };
  
  const onChange = value => {
    console.log(`selected ${value}`);
    setinputsValue({ ...inputsValue, type: value });
  };
  const onChanGeguests_type = value => {
    console.log(`selected ${value}`);
    setinputsValue({ ...inputsValue, guests_type: value });
  };
 
  const onChangerent_type = e => {
    setinputsValue({ ...inputsValue, rent_type: e.target.value });
  };

  const onChangeSuitable_guests = checkedValues => {
    setinputsValue({ ...inputsValue, suitable_guests: checkedValues });
  };

  const onChangeregion_id = value => {
    console.log("value", value);
    setFromLoading(false);
   
    setinputsValue({
      ...inputsValue,
      city_id: "",
      district_id: "",
      region_id: value,
    });
    setTimeout(() => {
      setFromLoading(true);
    }, 0);
    get(`get-cities-by-region/${value}`).then(response => {
      console.log("responsedjkffjkld", response.data);
      setOptionscity_id(response.data);
      // settest('')
    });
  };
  const onChangecity_id = value => {
    console.log("value", value);
    setFromLoading(false);
    setTimeout(() => {
      setFromLoading(true);
    }, 0);
    setinputsValue({ ...inputsValue, city_id: value, district_id: "" });

    get(`get-districts-by-city/${value}`).then(response => {
      console.log("responsedjkffjkld", response.data);
      setOptionsdistrict_id(response.data);
      // settest('')
    });
  };
  const onChangedistrict_id = value => {
    console.log("value", value);
    setinputsValue({ ...inputsValue, district_id: value });
  };

 



  const [markerPosition, setMarkerPosition] = useState({ lat: 24.7136, lng: 46.6753 }); // Default position (Riyadh)
  const [searchQuery, setSearchQuery] = useState('');
  const autocompleteRef = useRef(null); // Reference for the Autocomplete input field
  const mapContainerStyle = {
    width: '60vw',
    height: '60vh',
  };

  const containerStyle = {
    position:'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
     width: '100vw',
     backgroundColor: '#fff',
    
  };

  const handleMapClick = (e) => {
    const { lat, lng } = e.latLng.toJSON();
    setMarkerPosition({ lat, lng }); // Update marker position on map click
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Handle place selection from autocomplete input
  const handlePlaceSelect = () => {
    const place = autocompleteRef.current.getPlace();
    if (place.geometry) {
      // Get the location from the selected place
      const { lat, lng } = place.geometry.location;
      setMarkerPosition({ lat: lat(), lng: lng() });
    }
  };

  return (
    <div>
      <Steper current={2} />

      {fromloading ? (
        <Form
          onFinish={onFinish}
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            name: inputsValue.name,
            property_number: inputsValue.property_number,
            type: inputsValue.type,
            rent_type: inputsValue.rent_type,
            description: inputsValue.description,
            property_aria: inputsValue.property_aria,
            address_map: inputsValue.address_map,
            twitter_link: inputsValue.twitter_link,
            instagram_link: inputsValue.instagram_link,
            pinterest_link: inputsValue.pinterest_link,
            guests_type: inputsValue.guests_type,
            district_id: inputsValue.district_id,
            region_id: inputsValue.region_id,
            city_id: inputsValue.city_id,
            suitable_guests: inputsValue.suitable_guests,
          }}
          autoComplete="off"
        >
          <div className="row">
            <div className="col-lg-4">
              <Form.Item
                label="إسم الوحدة "
                name="name"
                onChange={handleChange}
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                label="رقم الوحدة"
                name="property_number"
                onChange={handleChange}
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                label=" نوعية الوحدة"
                name="type"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Select
                  className="w-100"
                  showSearch
                  placeholder=" حدد نوعية الوحدة"
                  optionFilterProp="children"
                  onChange={onChange}
                  filterOption={(input, option) =>
                      (option?.name_ar ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={propertyTypes}
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                label="ضيوف الوحدة"
                name="guests_type"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد ضيوف الوحدة"
                  optionFilterProp="children"
                  onChange={onChanGeguests_type}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={radioguests_type}
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                label="نوع الإيجار"
                name="rent_type"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Radio.Group
                  onChange={onChangerent_type}
                  value={inputsValue.rent_type}
                >
                  {rentTypes.map(item => {
                    return <Radio value={item.id}> {item.name} </Radio>;
                  })}
                </Radio.Group>
              </Form.Item>
            </div>

            <div className="col-lg-4">
              <Form.Item
                label="الوحدة مناسبة"
                name="suitable_guests"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Checkbox.Group
                  options={optionsSuitableGuestsData}
                  defaultValue={[inputsValue.suitable_guests]}
                  onChange={onChangeSuitable_guests}
                />
               </Form.Item>
            </div>

            <div className="col-lg-4">
              <Form.Item
                label="الوصف"
                name="description"
                onChange={handleChange}
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <TextArea value={inputsValue.description} />
                {/* <Input /> */}
              </Form.Item>
            </div>

            <div className="col-lg-4">


              <Form.Item
                label="مساحة الوحدة "
                name="property_aria"
                onChange={handleChange}
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </div>

           

            <div className="col-lg-4">
              <Form.Item
                label="المنطة"
                name="region_id"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد المنطة"
                  optionFilterProp="children"
                  onChange={onChangeregion_id}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={optionsregion_idData}
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                label="المدينة"
                name="city_id"
                value={inputsValue.city_id}
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد المدينة"
                  optionFilterProp="children"
                  onChange={onChangecity_id}
                  value={inputsValue.city_id}
                  filterOption={(input, option) =>
                    (dataCities?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={optionscity_idData}
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                label="الحى"
                name="district_id"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد الحى"
                  optionFilterProp="children"
                  onChange={onChangedistrict_id}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={optionsdistrict_idData}
                  rules={[
                    {
                      required: true,
                      message: "هذا الحقل مطلوب!",
                    },
                  ]}
                />
              </Form.Item>
            </div>

            <div className="col-lg-4">
              <Form.Item
                label="توتير"
                name="twitter_link"
                 onChange={handleChange}
                 rules={[
    {
      type: "url",
      message: "يرجى إدخال رابط صحيح.",
    },
    
  ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                label="انستجرام"
                name="instagram_link"
                rules={[
    {
      type: "url",
      message: "يرجى إدخال رابط صحيح.",
    },
    
  ]}
                onChange={handleChange}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-4">
              <Form.Item
                label="بينتـرست "
                name="pinterest_link"
                onChange={handleChange}
                rules={[
    {
      type: "url",
      message: "يرجى إدخال رابط صحيح.",
    },
    
  ]}
              >
                <Input />
              </Form.Item>
            </div>
            <div style={containerStyle}>
      <LoadScript googleMapsApiKey="AIzaSyCzrMXdUujfFZJqOL1kqiPFFi1jU_BMKwM" libraries={['places']}>
        {/* Search Input with Autocomplete */}
        <div style={{
          position: 'absolute', 
          top: '10px', 
          left: '50%', 
          transform: 'translateX(-50%)', 
          zIndex: '1000', 
          background: 'white', 
          padding: '5px', 
          borderRadius: '4px', 
          boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
        }}>
          <Autocomplete
            onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)} // Save the Autocomplete instance
            onPlaceChanged={handlePlaceSelect} // Trigger when place is selected
          >
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search for a location"
              style={{
                padding: '10px',
                width: '300px',
                borderRadius: '5px',
                border: '1px solid #ccc',
                fontSize: '14px',
              }}
            />
          </Autocomplete>
        </div>

        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          center={markerPosition}
          zoom={10}
          onClick={handleMapClick}
        >
          {/* Add Marker */}
          <Marker position={markerPosition} />
        </GoogleMap>
      </LoadScript>
    </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-4 offset-lg-1">
              <Button
                loading={loading}
                size="large"
                block
                type="primary"
                htmlType="submit"
              >
                {loading ? "جاري ..." : "التالي"}
              </Button>
            </div>

            <div className="col-lg-4 offset-lg-1">
              {/* <Button
                                    size='large' block type="primary">

                                </Button> */}
              <Button size="large" block>
                <Link
                  className="d-block text-decoration-none"
                  to="/add-property/step-1"
                >
                  {" "}
                  رجوع{" "}
                </Link>
              </Button>
            </div>
          </div>
        </Form>
      ) : (
        <div className="text-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default Step2;
