import React from 'react';
import { Navigate, Outlet } from "react-router-dom";
const useAuthAdmin = () => {

    const loggedInAdmin = localStorage.getItem('loggedInAdmin')
    return loggedInAdmin;
};

export const ProtectedRoutesAdmin = (e) => {
    const isAuth = useAuthAdmin();
    return isAuth ? <Outlet /> : <Navigate to="/login" />;
};
 
// checkPermission
export const checkPermission = (url) => {
    const permissionsAdmin = JSON.parse(localStorage.getItem("permissionsAdmin"))
        ? JSON.parse(localStorage.getItem("permissionsAdmin"))
        : [];
        console.log(permissionsAdmin);
        console.log(url);

    if (permissionsAdmin.includes(url)) {

        return true;
    } else {
        return false;
    }
};