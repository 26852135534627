import React, { useEffect, useState } from "react";
import { Table, Button, Tabs, message, Input } from "antd";
import { get, post } from "./../../api/axios";
import { Link } from "react-router-dom";
import { Pagination } from "antd";
import { checkPermission } from "../auth/ProtectedRoutes";
import {
  InstagramOutlined,
  PinterestOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import SearchBar from "../../utils/SearchBar";

const Properties = () => {
  const [filter, setFilter] = useState("published");
  const [data, setData] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedValue, setDebouncedValue] = useState(searchQuery);

  const updatePropertyStatus = (url, code, blockValue) => {
    setLoadingBtn(true);
    post(url, {
      code: code,
      block: blockValue,
      reason: "test",
      block_to: null,
    })
      .then((res) => {
        const updatedData = data?.filter((item) => item.code !== code);
        setData(updatedData);
        message.success("تم تغير الحالة بنجاح");
      })
      .catch((err) => {
        message.error("حدث خطأ ما!");
      })
      .finally(() => {
        setLoadingBtn(false);
      });
  };

  const handleStatus = (code) => {
    updatePropertyStatus("properties/publish", code, true);
  };

  const handleStatusBlock = (code) => {
    updatePropertyStatus("properties/block", code, 0);
  };

  const columns = [
    {
      title: (
        <>
          الصورة
          <br />
          كود الوحدة
        </>
      ),
      render: ({ id, image }) => (
        <>
          <img
            width={100}
            height={100}
            src={image}
            style={{ marginBottom: "5px" }}
          />{" "}
          <br />
          {id}
        </>
      ),
    },
    {
      title: (
        <>
          أسم المضيف <br />
          البريد الإلكتروني <br />
          الجوال <br />
          رقم المضيف <br />
          الجنسية <br />
          الإقامة <br />
          رقم الاثبات <br />
          الجنس- تاريخ الميلاد <br />
          العنوان <br />
        </>
      ),
      render: ({ owner }) => (
        <>
          {owner?.name || "-"} <br />
          {owner?.email || "-"} <br />
          {owner?.phone || "-"} <br />
          {owner?.id || "-"} <br />

           {owner?.nationality?.nationality_ar || "-"} <br />
          {owner?.residence_type || "-"} <br />
          {owner?.id_number || "-"} <br />
          {owner?.dob_gender || "-"} <br />
          {owner?.address || "-"} <br />
        </>
      ),
    },
    {
      title: (
        <>
          اسم الوحدة <br />
          نوع الوحدة <br />
          كود الوحدة <br />
          المنطقة <br />
          المدينة <br />
          الحي \ القرية <br />
          رابط جوجل ماب <br />
          الأسم على جوجل ماب <br />
          تصنيف الوحدة <br />
        </>
      ),
      render: ({
        name,
        type,
        google_commercial_name,
        total_rate,
        address_map,
        code,
        region,
        district,
        city,
      }) => (
        <>
          {name || "-"} <br />
          {type || "-"} <br />
          {code || "-"} <br />
          {region || "-"} <br />
          {city || "-"} <br />
          {district || "-"} <br />
          {address_map || "-"} <br />
          {google_commercial_name || "-"} <br />
          {total_rate.number || "-"} <br />
        </>
      ),
    },
    {
      title: (
        <>
          نوع الملكية <br />
          السجل التجاري <br />
          اسم المنشاة
          <br />
          نوعية الاستخدام <br />
          ضيوف الوحدة
          <br />
          عدد الصور
          <br />
          استلام المبالغ <br />
          سياسة الإلغاء <br />
          التامين <br />
        </>
      ),
      render: ({
        is_commercial,
        commercial_record,
        commercial_record_name,
        rent_type,
        suitable_guests,
        images_count,
        image_insurance_cancellation,
      }) => (
        <>
          {is_commercial ? "تجاري" : "شخصي"} <br />
          {commercial_record} <br />
          {commercial_record_name} <br />
          {rent_type} <br />
          {suitable_guests.join("  ")} 
          <br />
          {images_count} <br />
          {image_insurance_cancellation.receive_money} <br />
          {image_insurance_cancellation.cancellation_policy} <br />
          {image_insurance_cancellation.insurance_amount} <br />
        </>
      ),
    },
    {
      title: (
        <>
          <div> نوع مدة الاقامة </div>
          <div> اوقات الوصول والمغادرة </div>
          <div>تاريخ الحساب </div>
          <div>تاريخ النموذج </div>
          <div> تاريخ الاتفاقية</div>
          {filter === "published" ? (
            <>
              {" "}
              <div>تاريخ نشر الوحدة </div>
            </>
          ) : (
            ""
          )}
          <div>رقم الموظف </div>
        </>
      ),
      render: ({
        arrive_leave,
        created_at,
        published,
        agreement_at,
        owner,
        status_logs,
      }) => (
        <>
          {arrive_leave.map((item) => (
            <>
              {item.type} <br />
              {item.arrive_time} <br />
              {item.leave_time} <br />
            </>
          ))}
          <br />
          {owner?.created_at}
          <br />
          {created_at}
          <br />
          {agreement_at}
          <br />
          {filter === "published" ? (
            <>
              {" "}
              {published?.published_at}
              <br />
            </>
          ) : (
            ""
          )}
          {published?.publisher_name}
          <br />
        </>
      ),
    },
    {
      title: "الاسعار",
      children: [
        {
          title: " الخميس",
          render: ({ prices }) => (
            <>
              {prices.thu.map((item) => (
                <>
                  {" "}
                  {item.type} <br /> {item.price}{" "}
                </>
              ))}
            </>
          ),
        },
        {
          title: " الجمعة        ",
          render: ({ prices }) => (
            <>
              {prices.fri.map((item) => (
                <>
                  {" "}
                  {item.type} <br /> {item.price}{" "}
                </>
              ))}
            </>
          ),
        },
        {
          title: " السبت        ",
          render: ({ prices }) => (
            <>
              {prices.sat.map((item) => (
                <>
                  {" "}
                  {item.type} <br /> {item.price}{" "}
                </>
              ))}
            </>
          ),
        },
        {
          title: " وسط الاسبوع        ",
          render: ({ prices }) => (
            <>
              {prices.rest_of_week.map((item) => (
                <>
                  {" "}
                  {item.type} <br /> {item.price}{" "}
                </>
              ))}
            </>
          ),
        },
      ],
    },
    {
      title: (
        <>
          تويتير <br />
          انستغرام <br />
          بينترست
        </>
      ),
      render: ({ instagram_link, pinterest_link, twitter_link }) => (
        <>
          <a
            className="color-inherit text-decoration-none px-2 d-block"
            target="_blank"
            rel="noreferrer"
            href={instagram_link}
          >
            <InstagramOutlined style={{ fontSize: "20px" }} />
          </a>
          <br />
          <a
            className="color-inherit text-decoration-none px-2 d-block"
            target="_blank"
            rel="noreferrer"
            href={twitter_link}
          >
            <TwitterOutlined style={{ fontSize: "20px" }} />
          </a>
          <br />
          <a
            className="color-inherit text-decoration-none px-2 d-block"
            target="_blank"
            rel="noreferrer"
            href={pinterest_link}
          >
            <PinterestOutlined style={{ fontSize: "20px" }} />
          </a>
        </>
      ),
    },
    {
      title: filter === "incomplete" ? "الخطوات" : "تغير الحالة",
      render: ({ code, completed_steps }) => (
        <>
          {filter === "incomplete" ? (
            <>{completed_steps}</>
          ) : filter === "pending" ? (
            <button
              onClick={() => handleStatus(code)}
              disabled={loadingBtn}
              className="btn btn-warning py-0"
            >
              عرض الوحدة
            </button>
          ) : filter === "blocked" ? (
            <button
              onClick={() => handleStatusBlock(code)}
              disabled={loadingBtn}
              className="btn btn-success py-0"
            >
              اتاحة
            </button>
          ) : (
            <Link
              to={`/properties/change-status-properties/${code}`}
              className="btn btn-warning py-0"
            >
              إيقاف
            </Link>
          )}
        </>
      ),
    },
  ];

  if (filter === "blocked") {
    const blockedColumn = {
      title: (
        <>
          <div>تاريخ الايقاف</div>
          <div>السبب</div>
          <div>تاريخ الاتاحة</div>
        </>
      ),
      dataIndex: "status_logs",
      render: (status_logs) => (
        <>
          {status_logs && status_logs.length > 0 ? (
            status_logs.map((item, index) => (
              <div key={index}>
                {item.created_at || "N/A"}
                <br />
                {item.reason || "N/A"}
                <br />
                {item.block_to || "N/A"}
                <br />
              </div>
            ))
          ) : (
            <div>لا توجد بيانات</div>
          )}
        </>
      ),
    };
    columns.splice(5, 0, blockedColumn);
  }

  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState(1);

  useEffect(() => {
    get(`properties?status=${filter}&search=${searchQuery}`).then((res) => {
      setData(res?.data.data);
      console.log(searchQuery);

      setTotal(res?.data.meta.last_page);
      setLoading(false);
    });
  }, [debouncedValue]);
  const handlePagination = (pageNumber) => {
    setLoading(true);

    get(`properties?page=${pageNumber}&search=${searchQuery}`).then((res) => {
      setData(res?.data.data);
      setTotal(res?.data.meta.last_page);
      setLoading(false);
    });
  };

  const onChangeTabs = (key) => {
    setFilter(key);
    setLoading(true);

    get(`properties?status=${key}`).then((res) => {
      setData(res?.data.data);
      setTotal(res?.data.meta?.last_page);
      setLoading(false);
    });
  };

  const items = [
    {
      key: "published",
      label: "معروضة",
    },
    {
      key: "pending",
      label: "غير معروضة",
    },
    {
      key: "incomplete",
      label: "غير مكتملة",
    },
    {
      key: "blocked",
      label: "موقوفة  ",
    },
  ];

  return (
    <div>
      <div className="row">
        {checkPermission("create-property") && (
          <div className="col-lg-4 offset-lg-4 mb-3">
            <Link
              className="text-decoration-none d-block h-100"
              to="/add-property/step-1"
            >
              <Button type="primary" block>
                {" "}
                إضافة{" "}
              </Button>
            </Link>
          </div>
        )}
        <SearchBar
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          debouncedValue={debouncedValue}
          setDebouncedValue={setDebouncedValue}
        />

        <div className="col-12 grid-margin stretch-card tabs-section">
          <Tabs
            size="large"
            defaultActiveKey="agreed"
            items={items}
            onChange={onChangeTabs}
          />
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              bordered
              scroll={{
                x: 1550,
                y: "65vh",
              }}
              columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
            {/* <div className='text-center mt-3 btn-delete-table'>
              <Button type="primary" danger  size='large' >
                حذف
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Properties;
