import { SearchOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

const SearchBar = ({
  setSearchQuery,
  searchQuery,
  debouncedValue,
  setDebouncedValue,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(searchQuery);
    }, 300); // 300ms delay

    return () => clearTimeout(timer);
  }, [searchQuery]);

  const handleSearch = () => {
    setSearchQuery(searchQuery);
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch(); 
    }
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "20px",
        position: "relative",
      }}
    >
      <input
        type="text"
        placeholder="بحث..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyDown={handleKeyDown} 
        style={{
          flex: 1,
          padding: "8px 30px 8px 40px",
          fontSize: "16px",
          border: "1px solid #d9d9d9",
          borderRadius: "4px",
          outline: "none",
          transition: "border-color 0.3s",
        }}
      />
      <SearchOutlined
        onClick={handleSearch}
        style={{
          position: "absolute",
          right: "20px", // Position the icon inside the input field
          fontSize: "18px",
          color: "#999",
          cursor: "pointer",
        }}
      />
    </div>
  );
};

export default SearchBar;
