import React, { useEffect, useState } from 'react';
import { axiosApi1, postFromData, tokenAdmin } from '../../../../api/axios';
import { Button, Checkbox, Divider, Form, Input, message, Radio, Space, Spin, Typography, Upload } from 'antd';
import Steper from '../layoutProperty/Steper';
import { Link, useNavigate } from 'react-router-dom';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import IntlTelInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
const Step7 = () => {
    const [loading, setLoading] = useState(false);
    const [fromloading, setFromLoading] = useState(false);
    const { Paragraph } = Typography;
    const { TextArea } = Input;
    const navigate = useNavigate();

    const [inputsValue, setinputsValue] = useState({})
    const [instructions, setInstructions] = useState('');

    useEffect(() => {
        // return () => {

        if (localStorage.getItem('codeAddProperty')) {
            postFromData('add-property/step-7', { code: localStorage.getItem('codeAddProperty'), ignore_update_level: true }).then(res => {
                setinputsValue(res.data)
                setFromLoading(true);
                setInstructions(res.data.property.arrive_instructions)
            });
        } else {
            navigate('/add-property/step-1');
        }
        // }
    }, []);


    const onFinish = (values) => {
        setLoading(true);
        const data = {
            ...values,
            'code': localStorage.getItem('codeAddProperty'),
        };

        if (!data.urls) {
            data.urls = [];
        }
        if (values.images_one) {
            console.log('images_one');
            data.urls.push(
                values.images_one.file.response.url
            );
        }
        if (values.images_two) {
            console.log('images_two');
            data.urls.push(values.images_two.file.response.url);
        }
        axiosApi1.post(
            'add-property/step-7',
            data
        )
            .then(res => {
                setLoading(false);
                navigate('/add-property/step-8')
            })
            .catch(err => {
                message.error(err.response.data.message);
                setLoading(false);
            })
    };
    
    
    const [administrato, setadministrato] = useState('some');

    

const onChangebank_administrato = (e) => {
    setadministrato(e);
     if (e === 'some') {
        setinputsValue({
            ...inputsValue,
            property: {
                ...inputsValue.property,
                administrator_name: inputsValue.owner.name,
                administrator_phone: inputsValue.owner.phone
            }
        });
    } else {
         setinputsValue({
            ...inputsValue,
            property: {
                ...inputsValue.property,
                administrator_name: '',
                administrator_phone: ''
            }
        });
    }
};
    const optionsProperty_permissions = [
        {
            label: 'الحساب وإدارة الحجوزات',
            value: 'account',
        },
        {
            label: 'استقبال الرسائل النصية',
            value: 'receive_sms',
        },
        {
            label: 'التواصل مع الضيوف',
            value: 'contact_guests',
        },
        {
            label: 'التواصل مع مكان',
            value: 'contact_makan',
        },
    ];




    const onChangeInstructions = (e) => {
        // console.log('radio checked', e.target.value);
        setInstructions(e.target.value);
    };


    const handleDescription = e => {
        setinputsValue({ ...inputsValue, 'property': { ...inputsValue.property, 'instructions_description': e.target.value } })
    }

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                تحميل
            </div>
        </div>
    );
    return (
        <div className='add-prop step-6'>
            <Steper current={7} />


            {
                fromloading ?
                    <Form
                        onFinish={onFinish}
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}

                        initialValues={{
        "name": inputsValue?.owner?.name || "",
        "phone": inputsValue?.owner?.phone || "",
        "administrator_name": inputsValue?.property?.administrator_name || "",
        "administrator_phone": inputsValue?.property?.administrator_phone || "",
        "Property_permissions": inputsValue?.property?.Property_permissions || [],
        "arrive_instructions": inputsValue?.property?.arrive_instructions || "",
        "instructions_description": inputsValue?.property?.instructions_description || "",
    }}
                    >

                        <div className='row'>
                            <div className='col-lg-9'>
                                <Divider orientation="left" className='text-main'> <span className='text-main'> اختر مديراً للحجوزات  </span> </Divider>
                                <Radio className='w-100 mb-3' value='some' onChange={() => onChangebank_administrato('some')} checked={administrato === 'some' ? true : false} >  انا من سيقوم بإدارة الحجوزات </Radio>
                                {
                                    administrato == 'some' ?

                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <Form.Item
                                                    label=' مدير الحجوزات'
                                                    name='name'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل مطلوب!',
                                                        },
                                                    ]}
                                                >
                                                    <Input value={inputsValue.owner.name} disabled />
                                                </Form.Item>

                                            </div>
                                            <div className='col-lg-6'>
                                                <Form.Item
                                                    label=' رقم الجوال'
                                                    name='phone'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل مطلوب!',
                                                        },
                                                    ]}
                                                >
                                                    <Input value={inputsValue.owner.phone} disabled />
                                                </Form.Item>

                                            </div>
                                        </div>
                                        :
                                        null}
                                <Radio className='mb-3' value='other' onChange={() => onChangebank_administrato('other')} checked={administrato === 'other' ? true : false} > شخص أخر سيتولى إدارة الحجوزات </Radio>
                                {
                                    administrato == 'other' ?

                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <Form.Item
                                                    label=' مدير الحجوزات'
                                                    name='administrator_name'
                                                    onChange={(e) => 
    setinputsValue({
        ...inputsValue,
        property: {
            ...inputsValue.property,
            administrator_name: e.target.value
        }
    })
}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل مطلوب!',
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>

                                            </div>
                                            <div className='col-lg-6'>
    <Form.Item
        label=' رقم الجوال'
        name='administrator_phone'
 
        rules={[
            {
                required: true,
                message: 'هذا الحقل مطلوب!',
            },
        ]}
    >
       <IntlTelInput
    preferredCountries={['sa', 'eg', 'ae', 'kw']}
    containerClassName="intl-tel-input"
    inputClassName="form-control"
     

            onChange={(value) => 
    setinputsValue(prev => ({
        ...prev,
        property: {
            ...(prev?.property || {}),
administrator_phone: value ? value.replace("+", "") : ""        }
    }))
}
    value={inputsValue.administrator_phone || ""}
    separateDialCode
    defaultCountry="SA"
/>
        {/* <Input/> */}
    </Form.Item>
</div>

                                        </div>

                                        : null
                                }


                                <Divider orientation="left" className='text-main'> <span className='text-main'> الامتيازات والصلاحيات  </span> </Divider>

                                <Form.Item
                                    // label='الامتيازات والصلاحيات'
                                    name='Property_permissions'
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: 'هذا الحقل مطلوب!',
                                //     },
                                // ]}
                                >
                                    <Checkbox.Group options={optionsProperty_permissions} />

                                </Form.Item>


                                <Divider orientation="left" className='text-main'> <span className='text-main'> أضف تعليمات الوصول  </span> </Divider>

                                <Paragraph>
                                    يمكنك أضافة تعليمات الوصول األن او يمكنك القيام بذلك الحق ًا في منصتك
                                </Paragraph>
                                <Paragraph>
                                    تعليمات الوصول هي تفاصيل إرشادية قد يقدمها بعض المضيفين للضيوف لتسهيل وصولهم ودخولهم مكان اإلقامة، على سبيل المثال وليس الحصر )دخول مكان اإلقامة ذاتي وتجد مفتاح الباب تحت )السجادة(، يمكنك أيضا إرفاق صورة المبنى والمدخل
                                </Paragraph>

                                <Form.Item
                                    className='mt-3'
                                    name='arrive_instructions'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'هذا الحقل مطلوب!',
                                        },
                                    ]}
                                >
                                    <Radio.Group onChange={onChangeInstructions} value={inputsValue.property.arrive_instructions}>
                                        <Space direction="vertical">
                                            <Radio value={"share_instructions"}>
                                                سوف اقوم بمشاركة تعلميات الوصول مع الضيف بعد الحجز في المحادثات الفورية او الوتساب.
                                            </Radio>
                                            <Radio value={"no-instructions"}>
                                                ال يوجد تعلميات ، يمكن للضيف التوجه مباشرة وهناك من يستقبله لتسهيل دخوله الوحدة.
                                            </Radio>
                                            <Radio value={"custom-instructions"}>
                                                أرغب في عرض تعليمات الوصول والدخول في بطاقة الحجز والتعليمات كالتالي:

                                            </Radio>
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>

                                {instructions === "custom-instructions" ? (
                                    <>
                                        <Form.Item
                                            name='instructions_description'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'هذا الحقل مطلوب!',
                                                },
                                            ]}
                                        >

                                            <div>
                                                <TextArea className='w-100' value={inputsValue.property.instructions_description} onChange={handleDescription} rows={4} />
                                            </div>

                                        </Form.Item>

                                        <div className='row'>
                                            <div className='col'>

                                                <Form.Item
                                                    name='images_one'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل مطلوب!',
                                                        },
                                                    ]}
                                                >
                                                    <Upload
                                                        action="https://test.book-makan.com/api/admin/upload/store"
                                                        headers={{ "Authorization": `Bearer ${tokenAdmin}` }}
                                                        listType="picture"
                                                        maxCount={1}
                                                    >
                                                        {/* {uploadButton} */}
                                                        <Button icon={<UploadOutlined />}> اضغظ لتحميل الصورة </Button>
                                                    </Upload>

                                                </Form.Item>
                                            </div>
                                            <div className='col'>

                                                <Form.Item
                                                    name='images_two'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل مطلوب!',
                                                        },
                                                    ]}
                                                >
                                                    <Upload
                                                        action="https://test.book-makan.com/api/admin/upload/store"
                                                        headers={{ "Authorization": `Bearer ${tokenAdmin}` }}
                                                        listType="picture"
                                                        maxCount={1}
                                                    >
                                                        {/* {uploadButton} */}
                                                        <Button icon={<UploadOutlined />}> اضغظ لتحميل الصورة </Button>
                                                    </Upload>

                                                </Form.Item>
                                            </div>
                                        </div>
                                    </>

                                ) : null}

                                {/* <Form.Item
                                    label=' البنك'
                                    name='bank_account_name'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'هذا الحقل مطلوب!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item> */}
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-lg-4 offset-lg-1'>

                                <Button
                                    loading={loading}
                                    size='large' block type="primary" htmlType="submit">
                                    {loading ? 'جاري ...' : 'التالي'}
                                </Button>
                            </div>
                            <div className='col-lg-4 offset-lg-1'>
                                <Button size='large' block>
                                    <Link className='d-block text-decoration-none' to='/add-property/step-6'> رجوع </Link>
                                </Button>

                            </div>

                        </div>
                    </Form>


                    :
                    <div className='text-center'>
                        <Spin size="large" />
                    </div>

            }
        </div>
    );
}

export default Step7;
