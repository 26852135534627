import React, { useEffect, useState } from "react";
import { Table, Input } from "antd";
import { get } from "../../api/axios";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import { FileAddOutlined } from "@ant-design/icons";
import SearchBar from "../../utils/SearchBar";

const Reservations = () => {
  const columns = [
    {
      title: "رقم الحجز ",
      dataIndex: "id",
    },
    {
      title: "الصورة ",
      dataIndex: "property",
      render: (property) => (
        <div>
          <img
            src={property.image}
            alt="alt"
            class="rounded"
            style={{
              height: "70px",
              width: "70px",
              objectFit: "cover",
            }}
          />
        </div>
      ),
    },
    {
      title: "بيانات الوحدة ",
      // dataIndex: "",
      render: ({ property, cancellation_policy }) => (
        <>
          الوحدة: {property.name}
          <br />
          المدينة: {property.city}
          <br />
          الحي: {property.district}
          <br />
          كود الوحدة: {property.code}
          <br />
          سياسة الإلغاء : {cancellation_policy}
        </>
      ),
    },
    {
      title: "بيانات الإقامة ",
      render: ({ arrives_leaves, daysNumbers }) => (
        <>
          تاريخ الوصول والمغادرة: {arrives_leaves.date} <br />
          وقت الوصول: {arrives_leaves.arriving_time} <br />
          وقت المغادرة: {arrives_leaves.leaving_time} <br />
          نوع الإقامة: {arrives_leaves.type} <br />
          مدة الإقامة: {daysNumbers}
        </>
      ),
    },
    {
      title: "بيانات الضيف ",
      render: ({ user }) => (
        <>
          {/* رقم الضيف : {user.id} <br /> */}
          الأسم : {user.name} <br />
          البريد الإلكتروني : {user.email} <br />
          الجوال : {user.phone} <br />
        </>
      ),
    },
    {
      title: "بيانات الحجز  ",
      render: ({
        id,
        status,
        reservation_source,
        date_of_creation,
        time_of_creation,
        reservation_os,
      }) => (
        <>
          رقم الحجز : {id} <br />
          حالة الحجز : {status} <br />
          مصدر الحجز : {reservation_source === "user" ? "ضيف" : "مضيف"} <br />
          جهة الحجز: {reservation_os} <br />
          انشاء الحجز : {date_of_creation} {time_of_creation} <br />
        </>
      ),
    },
    {
      title: "بيانات السعر والدفع",

      render: ({ prices, daysNumbers, total_price }) => (
        <>
          طريقة السداد: {} <br />
          {daysNumbers} * {prices.avg_price} <br />
          إجمالي السعر :{prices.subTotal} <br />
          العرض: {prices.offers_amount} <br />
          كود خصم: {prices.coupon_amount} <br />
          مبلغ الحجز : {prices.total_price_without_service} <br />
          رسوم الخدمة: {prices.total_service} <br />
          المجموع: {total_price} <br />
        </>
      ),
    },
    {
      title: " تقييم الضيف ",
      render: ({ rate }) => (
        <>
          {rate && (
            <>
              {rate.date} <br />
              التقييم العام : {rate.total_rating} <br />
              نظافة المكان : {rate.clean} <br />
              حالة المكان : {rate.new} <br />
              خدمة المضيف : {rate.service} <br />
              الجودة والسعر : {rate.pricing} <br />
              تطابق المواصفات : {rate.matching} <br />
            </>
          )}
        </>
      ),
    },
    {
      title: " تقييم المضيف ",
      render: ({ owner_rate }) => (
        <>
          {owner_rate && (
            <>
              {owner_rate.date} <br />
              التقييم العام : {owner_rate.total_rating} <br />
              نظافة المكان : {owner_rate.clean} <br />
              الحرص : {owner_rate.care} <br />
              التعليمات : {owner_rate.instructions} <br />
              الرضا : {owner_rate.satisfaction} <br />
              اللباقة : {owner_rate.tact} <br />
            </>
          )}
        </>
      ), 
    },
    // {
    //   title: "انشاء حوالة",
    //   render: ({
    //     id,
    //     reservation_source,
    //     total_price,
    //     status,
    //     has_payout,
    //     is_payable,
    //   }) => (
    //     <>
    //       {reservation_source === "user" && (
    //         // <button
    //         //   disabled={!has_payout & is_payable ? false : true}
    //         //   className="btn btn-outline-warning btn-sm p-0"
    //         // >
    //         //   <Link
    //         //     className="color-inherit text-decoration-none py-1 px-2 d-block"
    //         //     to={`/payouts/create/${id}`}
    //         //   >
    //         //     {!has_payout ? (
    //         //       <>
    //         //         <FileAddOutlined />
    //         //         انشاء
    //         //       </>
    //         //     ) : (
    //         //       <>تم انشاء حوالة بالفعل</>
    //         //     )}
    //         //   </Link>
    //         // </button>
    //         <button className="btn btn-outline-warning btn-sm p-0">
    //           <Link
    //             className="color-inherit text-decoration-none py-1 px-2 d-block"
    //             to={{
    //               pathname: `/payouts/create/${id}`,
    //               state: { amount: total_price },
    //             }}
    //           >
    //             <>
    //               <FileAddOutlined />
    //               انشاء
    //             </>
    //           </Link>
    //         </button>
    //       )}
    //     </>
    //   ),
    // },
  ];

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedValue, setDebouncedValue] = useState(searchQuery);

  const [total, setTotal] = useState(1);

  useEffect(() => {
    get(`reservations?search=${searchQuery}`).then((res) => {
      setData(res?.data.data);
      setTotal(res?.data.meta.last_page);
      setLoading(false);
    });
  }, [debouncedValue]);

  const handlePagination = (pageNumber) => {
    setLoading(true);

    get(`reservations?page=${pageNumber}`).then((res) => {
      setData(res.data.data);
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="row">
        <SearchBar
          setSearchQuery={setSearchQuery}
          searchQuery={searchQuery}
          debouncedValue={debouncedValue}
          setDebouncedValue={setDebouncedValue}
        />
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              bordered
              scroll={{
                y: "65vh",
                x: 1650,
              }}
              columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reservations;
