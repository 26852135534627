import React, { useEffect, useState } from "react";
import { Input, Typography, Button, message, Form, Upload, Spin } from "antd";
import { baseUrl, put, tokenAdmin } from "../../api/axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";

const { Title } = Typography;

const EditHomeSlider = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  const sliderData = location.state?.sliderData;
  console.log(sliderData);
  const [inputsValue, setInputsValue] = useState({
    title: sliderData?.title || "",
    description: sliderData?.description || "",
    url: "",
  });
  const [loading, setLoading] = useState(false);

  // Handle form field changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setInputsValue((prev) => ({ ...prev, [id]: value }));
  };

  // Handle image upload
  const handleUpload = ({ fileList }) => {
    const uploadedUrl = fileList[0]?.response?.url || "";
    setInputsValue((prev) => ({ ...prev, url: uploadedUrl }));
  };

  // Handle form submission
  const onFinish = () => {
    setLoading(true);
    put(`home-sliders/${id}`, inputsValue)
      .then(() => {
        message.success("تم التعديل بنجاح");
        navigate("/home-sliders");
      })
      .catch((err) => {
        message.error(err.response?.data?.message || "حدث خطأ ما!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-4">
        تعديل صورة
      </Title>
      <Form
        onFinish={onFinish}
        labelCol={{ span: 7 }}
        wrapperCol={{ span: 15 }}
        initialValues={inputsValue}
        autoComplete="off"
      >
        <div className="row">
          <div className="col-lg-6">
            <Form.Item
              label="العنوان"
              name="title"
              rules={[{ required: true, message: "هذا الحقل مطلوب!" }]}
            >
              <Input
                id="title"
                value={inputsValue.title}
                onChange={handleChange}
              />
            </Form.Item>
          </div>
          <div className="col-lg-6">
            <Form.Item
              label="الوصف"
              name="description"
              rules={[{ required: true, message: "هذا الحقل مطلوب!" }]}
            >
              <TextArea
                id="description"
                value={inputsValue.description}
                onChange={handleChange}
              />
            </Form.Item>
          </div>
          <div className="col-lg-6">
            <Form.Item
              label="الصورة"
              name="url"
              rules={[{ required: true, message: "هذا الحقل مطلوب!" }]}
            >
              <Upload
                action={`${baseUrl}upload-slide-image/store`}
                headers={{ Authorization: `Bearer ${tokenAdmin}` }}
                listType="picture"
                maxCount={1}
                onChange={handleUpload}
                defaultFileList={
                  inputsValue.url
                    ? [{ url: inputsValue.url, name: "Current Image" }]
                    : []
                }
              >
                <Button icon={<UploadOutlined />}>اضغظ لتحميل الصورة</Button>
              </Upload>
            </Form.Item>
          </div>

          <div className="col-lg-4 offset-lg-4">
            <Form.Item wrapperCol={{ offset: 1, span: 16 }}>
              <Button
                loading={loading}
                size="large"
                block
                type="primary"
                htmlType="submit"
              >
                {loading ? "جاري الحفظ..." : "تعديل"}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default EditHomeSlider;
