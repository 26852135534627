import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Input, Dropdown, Menu, Badge, Avatar, Button } from "antd";
import {
  SearchOutlined,
  BellOutlined,
  UserOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { post } from "../../api/axios";
import { useLocation } from "react-router-dom";

const { Header } = Layout;

const Dashboard = () => {
  let location = useLocation();

  const handleMenuClick = (e) => {
    if (e.key === "logout") {
      // Handle logout logic here
    }
  };

  const handleLogout = () => {
    post("logout").then((res) => {
      localStorage.removeItem("tokenAdmin");
      localStorage.removeItem("authNameAdmin");
      localStorage.removeItem("loggedInAdmin");
      window.location.href = "/login";
    });
  };

  const userMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="logout" icon={<LogoutOutlined />}>
        <Button type="link" className="text-dark" onClick={handleLogout}>
          {" "}
          Logout{" "}
        </Button>
      </Menu.Item>
    </Menu>
  );

  const [userName, setuserName] = useState("");
  useEffect(() => {
    setuserName(localStorage.getItem("authNameAdmin"));
  }, []);

  return (
    <Header className="header-custom py-0 px-lg-3">
      <div className="row">
        <div className="col-12 col-lg-10 offset-lg-2 d-flex align-items-center justify-content-end justify-content-lg-end pe-lg-5">
          <Dropdown
            className="drop-user ms-3 ms-lg-4 me-1"
            overlay={userMenu}
            trigger={["click"]}
          >
            <div className="d-flex  align-items-center justify-content-around">
              <div className="text-center text-white lh-1">
                <h5> {userName} </h5>
                <span className="line-height-1">Superadmin</span>
              </div>
              <Avatar
                size={32}
                icon={<UserOutlined />}
                style={{ marginRight: 16 }}
              />
            </div>
          </Dropdown>
        </div>
      </div>
    </Header>
  );
};

export default Dashboard;
