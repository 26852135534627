import React, { useEffect, useState } from "react";
import { Table, Button } from "antd";
import { del, get, post, put } from "../../api/axios";
import { Link } from "react-router-dom";
import { message, Popconfirm } from "antd";
import { Pagination, Switch } from "antd";
import { checkPermission } from "../auth/ProtectedRoutes";

const Districts = () => {
  const columns = [
    {
      title: "رقم الحي",
      dataIndex: "id",
      render: status => <span>{status}</span>,
    },
    {
      title: "الاسم بالعربي",
      dataIndex: "name_ar",
    },
    {
      title: "الاسم بالانجليزي",
      dataIndex: "name_en",
    },
    {
      title: "اسم المدينة بالعربي",
      dataIndex: "city_name_ar",
    },
    {
      title: "اسم المدينة بالانجليزي",
      dataIndex: "city_name_en",
    },
    {
      title: "اسم المنطقة بالعربي",
      dataIndex: "region_name_ar",
    },
    {
      title: "اسم المنطقة بالانجليزي",
      dataIndex: "region_name_en",
    },
    {
      title: "الحالة",
      dataIndex: "id",
      render: (id, { status }) => (
        // <
        //     title="تغير الحالة"
        //     description="هل أنت متأكد من تغير الحالة؟"
        //     // onConfirm={() => confirm(id)}
        //     onCancel={cancel}
        //     okText="نعم"
        //     cancelText="لا"
        // >
        <>
          <Switch
            onChange={() => handleChangeStatus(id, status)}
            checked={status}
            style={{
              margin: 16,
            }}
          />
        </>
      ),
    },
  ];
  const [data, setdata] = useState([
    // {
    //     "id": 12,
    //     "status": true,
    //     "name_ar": "j",
    //     "name_en": "j",
    //     "city_id": 12,
    //     "city_name_ar": "الأحساء",
    //     "city_name_en": "Ehsaa",
    //     "region_name_ar": "مكة المكرمة",
    //     "region_name_en": "Makkah"
    // },
    // {
    //     "id": 11,
    //     "status": false,
    //     "name_ar": "d",
    //     "name_en": "h",
    //     "city_id": 12,
    //     "city_name_ar": "الأحساء",
    //     "city_name_en": "Ehsaa",
    //     "region_name_ar": "مكة المكرمة",
    //     "region_name_en": "Makkah"
    // },
    // {
    //     "id": 9,
    //     "status": true,
    //     "name_ar": "قرطبة",
    //     "name_en": "",
    //     "city_id": 7,
    //     "city_name_ar": "المدينة المنورة",
    //     "city_name_en": "Madinah Munawwarah",
    //     "region_name_ar": "مكة المكرمة",
    //     "region_name_en": "Makkah"
    // }
  ]);

  if (checkPermission("update-district")) {
    columns.push({
      title: "تعديل",
      dataIndex: "id",
      render: id => (
        <button className="btn btn-outline-warning btn-sm p-0">
          <Link
            className="color-inherit text-decoration-none py-1 px-2 d-block"
            to={`/districts/edit/${id}`}
          >
            تعديل
          </Link>
        </button>
      ),
    });
  }

  const [loading, setLoading] = useState(true);

  const [total, setTotal] = useState(1);

  const handleChangeStatus = (id, status) => {
    post(`change-district-status/${id}`, { status: !status });
    message.success("تم تغير الحالة");

    const newData = [];
    data.map(item => {
      if (item.id == id) {
        newData.push({ ...item, status: !item["status"] });
      } else {
        newData.push(item);
      }
    });
    setdata(newData);
    // console.log('newData',newData);
  };

  useEffect(() => {
    get("districts?page=1").then(res => {
      setdata(res.data.data);
      setTotal(res.data.meta.last_page);
      setLoading(false);
    });
  }, []);

  const handleDelet = id => {
    del(`districts/${id}`);
    let dateFilter = data.filter(item => item.id !== id);
    setdata(dateFilter);
  };

  const confirm = id => {
    console.log(id);
    handleDelet(id);
    message.success("تم الامر بنجاح");
  };
  const cancel = e => {
    console.log(e);
    message.error("تم الغاء الامر");
  };

 
   
  const handlePagination = pageNumber => {
    setLoading(true);

    get(`districts?page=${pageNumber}`).then(res => {
      setdata(res.data.data);
      setLoading(false);
    });
  };

  return (
    <div>
      <div className="row">
        {checkPermission("create-district") && (
          <div className="col-lg-4 offset-lg-4 mb-3">
              <Link
                className="text-decoration-none d-block h-100"
                to="/districts/add"
              >
                <Button type="primary" block>
                {" "}
                إضافة{" "}
            </Button>
              </Link>
          </div>
        )}
        <div className="col-12 grid-margin stretch-card">
          <div className="w-100">
            <Table
              locale={{ emptyText: "لا يوجد عناصر" }}
              loading={loading}
              pagination={false}
              scroll={{
                y: "65vh",
                x: 650,
              }}
               columns={columns}
              dataSource={data}
              size="middle"
            />
            <Pagination
              defaultCurrent={1}
              className="mt-4 d-flex align-items-center justify-content-center"
              position="bottomCenter"
              total={total * 10}
              onChange={handlePagination}
            />
            {/* <div className='text-center mt-3 btn-delete-table'>
              <Button type="primary" danger  size='large' >
                حذف
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Districts;
