import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  message,
  Select,
  Spin,
  Form,
  Upload,
} from "antd";
import { axiosApi1, get } from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { UploadOutlined } from "@ant-design/icons";

const { Title } = Typography;

const EditCity = () => {
  const navigate = useNavigate();
  const { cityId } = useParams();

  const [fromloading, setFromLoading] = useState(false);
  const [inputsValue, setinputsValue] = useState({ name_ar: "", name_en: "" });
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataRegions, setDataRegions] = useState([]);
  const [option, setOption] = useState([]);

  useEffect(() => {
    get(`cities/${cityId}`).then(res => {
      setinputsValue(res.data);
      setFromLoading(true);
      setFileList([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: res.data.image,
        },
      ]);
    });
  }, [cityId]);

  useEffect(() => {
    setOption(
      dataRegions.map(d => ({
        value: d.id,
        label: d.name_ar,
      }))
    );
  }, [dataRegions]);

  useEffect(() => {
    get("get-all-regions").then(res => {
      setDataRegions(res.data);
    });
  }, []);

  const handleChange = e => {
    setinputsValue({ ...inputsValue, [e.target.id]: e.target.value });
  };

   
  // };
  const handleUpload = ({ fileList: newFileList }) => {
    const file = newFileList[0];
    if (file && file.originFileObj) {
      setinputsValue({ ...inputsValue, image: file.originFileObj }); // Store the new image in state
    }
    setFileList(newFileList); 
  };
  

   
  //  const onPreview = async (file) => {
  //   let src = file.url;
  //   if (!src) {
  //     src = await new Promise((resolve) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file.originFileObj);
  //       reader.onload = () => resolve(reader.result);
  //     });
  //   }
  //   const imgWindow = window.open(src);
  //   imgWindow.document.write(`<img src="${src}" alt="Preview" />`);
  // };
  
  const onFinish = values => {
    setLoading(true);
    const formData = new FormData();
    formData.append("name_en", inputsValue.name_en);
    formData.append("name_ar", inputsValue.name_ar);
    formData.append("region_id", inputsValue.region_id);
    formData.append("_method", "PUT");
    if (typeof inputsValue.image !== "string") {
      formData.append("image", inputsValue.image);
    }
    if (!inputsValue.image ) {
      formData.append("image", null);  
    } else {
      if (typeof inputsValue.image !== "string") {
        formData.append("image", inputsValue.image);
      }    }
    axiosApi1
      .post(`cities/${cityId}`, formData)
      .then(res => {
        message.success("تم تعديل بنجاح");
        setLoading(false);
        navigate("/cities");
      })
      .catch(err => {
        message.error(err.response.data.message);
        setLoading(false);
      });
  };

  const onChange = value => {
    setinputsValue({ ...inputsValue, region_id: value });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-4">تعديل المدينة</Title>
      {fromloading ? (
        <Form
          onFinish={onFinish}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{
            name_ar: inputsValue.name_ar,
            name_en: inputsValue.name_en,
            region_id: inputsValue.region_id,
          }}
          autoComplete="off"
        >
          <div className="row">
            <div className="col-lg-6">
              <Form.Item
                label="الاسم بالعربي"
                name="name_ar"
                onChange={handleChange}
                rules={[{ required: true, message: "هذا الحقل مطلوب!" }]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                label="الاسم بالانجليزي"
                name="name_en"
                onChange={handleChange}
                rules={[{ required: true, message: "هذا الحقل مطلوب!" }]}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-6">
              <Form.Item
                label="المنطقة"
                name="region_id"
                rules={[{ required: true, message: "هذا الحقل مطلوب!" }]}
              >
                <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد المنطقة"
                  optionFilterProp="children"
                  onChange={onChange}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={option}
                />
              </Form.Item>
            </div>
            
            <div className="col-lg-6">
  <Form.Item label="الصورة" name="image">
    <Upload
      action="https://test.book-makan.com/api/admin/upload/store"
      headers={{ "api-key": "61e67458-888d-42b1-9dcb-bd1f89440101" }}
      listType="picture"
      maxCount={1}  
      onChange={handleUpload}
      fileList={fileList}  
      beforeUpload={() => false}  
      showUploadList={{ showRemoveIcon: false }}  
      onPreview={() => {
        document.querySelector("input[type='file']").click();  
      }}
    >
      {/* <Button icon={<UploadOutlined />}>اضغظ لتحميل الصورة</Button> */}
    </Upload>
  </Form.Item>
</div>


            <div className="col-lg-4 offset-lg-4">
              <Form.Item wrapperCol={{ offset: 1, span: 16 }}>
                <Button
                  loading={loading}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  {loading ? "جاري التعديل..." : "تعديل"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      ) : (
        <div className="text-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default EditCity;
