import { UploadOutlined, UserOutlined, LeftOutlined } from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import React, { useState, useEffect } from "react";
import AppRoutes from "../../AppRoutes";
import { Link, NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ReactComponent as Logo } from "./../../assets/img/logo.svg";
import Navbar from "./Navbar";
import { checkPermission } from "../auth/ProtectedRoutes";
import Title from "antd/es/typography/Title";

const { Header, Content, Footer, Sider } = Layout;
const LayoutCompoent = () => {
  let location = useLocation();

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const itemsMenu = [
    {
      key: "/unit-registrations",
      // className: !checkPermission("list-unit-registrations") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/تسجيل الوحدات.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/unit-registrations">
          تسجيل الوحدات
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/properties",
      className: !checkPermission("list-properties") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/العقارات.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/properties">
          {" "}
          العقارات
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/property-sales",
      className: !checkPermission("list-festival-prices") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/اداء الوحدات.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/property-sales">
          {" "}
          البيانات المالية <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/reservations",
      className: !checkPermission("list-reservations") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/الحجوزات.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/reservations">
          {" "}
          الحجوزات
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/guests",
      className: !checkPermission("list-guests") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/الضيوف.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/guests">
          {" "}
          الضيوف
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/makan-statistics",
      className: !checkPermission("list-makan-statistics") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/احصائيات مكان.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/makan-statistics">
          {" "}
          أحصائيات مكان
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/owners",
      className: !checkPermission("list-owners") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/الملاك.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/owners">
          {" "}
          الملاك
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/sales",
      className: !checkPermission("list-invoices") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/المبيعات.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/sales">
          {" "}
          المبيعات
          <LeftOutlined />
        </NavLink>
      ),
    },

    {
      key: "/invoices",
      className: !checkPermission("list-invoices") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/الفواتير.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/invoices">
          {" "}
          الفواتير
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/payouts",
      className: !checkPermission("list-payouts") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/الحوالات.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/payouts">
          {" "}
          الحوالات
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/refunds",
      className: !checkPermission("list-refund-invoices") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/الاسترداد.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/refunds">
          {" "}
          الأسترداد
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/chats",
      className: !checkPermission("list-chats") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/المحادثات.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/chats">
          {" "}
          المحادثات
          <LeftOutlined />
        </NavLink>
      ),
    },

    {
      key: "/suggestions",
      className: !checkPermission("list-suggestions") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/الاقتراحات.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/suggestions">
          {" "}
          الاقتراحات
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/contact-us",
      className: !checkPermission("list-contact-us") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/اتصل بنا.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/contact-us">
          {" "}
          اتصل بنا
          <LeftOutlined />
        </NavLink>
      ),
    },

    {
      key: "/makan-rates",
      className: !checkPermission("list-makan-rates") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/احصائيات مكان.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/makan-rates">
          {" "}
          أحصائية التقيمات
          <LeftOutlined />
        </NavLink>
      ),
    },

    {
      key: "/statistics",
      className: !checkPermission("list-unit-statistics") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/اداء الوحدات.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/statistics">
          {" "}
          اداء الوحدات
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/add-property",
      className: !checkPermission("create-property") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/اضف عقارك.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/add-property/step-1">
          {" "}
          إضافة عقار
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/setting",
      className: !checkPermission("settings") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/الاعدادات.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/setting">
          {" "}
          الاعدادات
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/offers",
      className: !checkPermission("list-offers") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/الخصومات.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/offers">
          {" "}
          الخصومات
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/coupons",
      className: !checkPermission("list-coupons") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/كود خصم.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/coupons">
          {" "}
          كود الخصم
          <LeftOutlined />
        </NavLink>
      ),
    },

    {
      key: "/festival-prices",
      className: !checkPermission("list-festival-prices") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/اسعار الاعياد.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/festival-prices">
          {" "}
          اسعار الاعياد
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/holidays-prices",
      className: !checkPermission("list-Holiday-prices") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/اسعار الاجازات.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/holidays-prices">
          {" "}
          اسعار الاجازات
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/users-rates",
      className: !checkPermission("list-users-rates") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/تقييم الضيف للوحدة.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/users-rates">
          {" "}
          تقييم الضيوف للوحدة
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/owners-rates",
      className: !checkPermission("list-owners-rates") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/تقييم المضيفين للضيوف.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/owners-rates">
          {" "}
          تقييم المضيفين للضيوف
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/roles",
      className: !checkPermission("list-roles") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/الصلاحيات.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/roles">
          {" "}
          الصلاحيات
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/marketers",
      className: !checkPermission("list-marketers") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/المسوقين.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/marketers">
          {" "}
          المسوقين
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/employees",
      className: !checkPermission("list-employees") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/الموظفين.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/employees">
          {" "}
          الموظفين
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/home-city-types",
      className: !checkPermission("list-home-city-types") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/انواع المدينة.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/home-city-types">
          {" "}
          أنواع المدينة
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/home-sliders",
      className: !checkPermission("list-home-sliders") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/صور الرئيسية.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/home-sliders">
          {" "}
          صور الرئيسية
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/regions",
      className: !checkPermission("list-regions") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/المنطقة.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/regions">
          المناطق
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/cities",
      className: !checkPermission("list-cities") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/المدن.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/cities">
          {" "}
          المدن
          <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/districts",
      className: !checkPermission("list-districts") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/الأحياء.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/districts">
          {" "}
          الاحياء <LeftOutlined />
        </NavLink>
      ),
    },
    {
      key: "/banks",
      className: !checkPermission("list-banks") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/البنوك.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/banks">
          {" "}
          البنوك
          <LeftOutlined />
        </NavLink>
      ),
    },

    {
      key: "/agreement",
      className: !checkPermission("show-agreement") && "d-none",
      icon: (
        <div
          className="sidebar-icon"
          style={{
            background: 'url("CRM ICONS/الاتفاقية.svg")',
          }}
        />
      ),
      label: (
        <NavLink to="/agreement">
          {" "}
          الاتفاقية
          <LeftOutlined />
        </NavLink>
      ),
    },
  ];
  const [layout, setlayout] = useState(true);
  const [isParmission, setIsParmission] = useState(false);

  useEffect(() => {
    const permissionsAdmin = JSON.parse(
      localStorage.getItem("permissionsAdmin")
    )
      ? JSON.parse(localStorage.getItem("permissionsAdmin"))
      : [];

    if (permissionsAdmin.length == 0) {
      setIsParmission(true);
    }
  }, []);

  useEffect(() => {
    console.log("location.pathname", location.pathname);
    if (location.pathname == "/login") {
      setlayout(false);
    }
  }, [location.pathname]);
  return (
    <>
      {layout ? (
        <Layout style={{ height: "100vh" }}>
          <Sider
            theme="light"
            breakpoint="lg"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log("broken", broken);
            }}
            onCollapse={(collapsed, type) => {
              console.log(collapsed, type);
            }}
          >
            <div className="demo-logo-vertical mt-lg-3 mb-lg-4">
              <Link className="text-center d-block" to="/">
                <Logo />
              </Link>
            </div>
            <Menu
              theme="light"
              mode="inline"
              defaultSelectedKeys={location.pathname}
              items={itemsMenu}
            />
            {isParmission && (
              <Title className="text-main text-center" level={5}>
                {" "}
                ليس لديك اي صلاحيات{" "}
              </Title>
            )}
          </Sider>
          <Layout>
            {/* <Header
                                style={{
                                    padding: 0,
                                    background: colorBgContainer,
                                }}

                            > */}
            <Navbar />
            {/* </Header> */}
            <Content
              style={{
                margin: "24px 16px 0",
              }}
            >
              <div
                style={{
                  padding: 24,
                  minHeight: 360,
                  background: colorBgContainer,
                }}
              >
                <AppRoutes />
              </div>
            </Content>
            {/* <Footer
                                style={{
                                    textAlign: 'center',
                                }}
                            >
                                Ant Design ©2023 Created by Ant UED
                            </Footer> */}
          </Layout>
        </Layout>
      ) : (
        <AppRoutes />
      )}
    </>
  );
};
export default LayoutCompoent;
