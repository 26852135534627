import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  message,
  Select,
  Form,
  DatePicker,
  Spin,
} from "antd";
import { get, postFromData } from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";

const { Title } = Typography;

const ChangeStatusOwner = () => {
  const navigate = useNavigate();
  const { TextArea } = Input;
  let { ownerId } = useParams();
  const [status, setStatus] = useState(null);
  const [inputsValue, setinputsValue] = useState({
    reason: "",
    owner_id: "",
    status: "",
    block_to: "",
  });

  useEffect(() => {
    setinputsValue({ ...inputsValue, owner_id: ownerId });
  }, []);

  const [fromloading, setFromLoading] = useState(false);
  // Fetch owner data and update the state after data retrieval

  useEffect(() => {
    get(`owners/${ownerId}`).then((res) => {
      setinputsValue({
        ...res.data,
        owner_id: ownerId,
        status: res.data.status,
      });
      setFromLoading(true);
      setStatus(res.data.status);
    });
  }, [ownerId]);

  const handelStatusUser = (value) => {
    setinputsValue({ ...inputsValue, status: value });
    setStatus(value);
  };

  const handleChange = (e) => {
    setinputsValue({ ...inputsValue, [e.target.id]: e.target.value });
  };

  const handleBlockTo = (date, dateString) => {
    setinputsValue({ ...inputsValue, block_to: dateString });
  };

  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    postFromData("change-owner-status", inputsValue)
      .then((res) => {
        message.success("تم الإضافة بنجاح");
        setLoading(false);
        navigate("/owners");
      })
      .catch((err) => {
        message.error(err.response.data.message);
        setLoading(false);
      });
  };

  return (
    <div>
      <Title level={2} className="text-main mb-4">
        {" "}
        تغيير حالة الضيف{" "}
      </Title>
      {fromloading ? (
        <Form
          onFinish={onFinish}
          labelCol={{
            span: 7,
          }}
          wrapperCol={{
            span: 15,
          }}
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
        >
          <div className="row">
            <div className="col-lg-4">
              <Form.Item
                label="الحالة"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
                name="status"
              >
                <Select
                  className="w-100"
                  placeholder={status}
                  onChange={handelStatusUser}
                  options={[
                    {
                      value: "1",
                      label: "مفعل",
                    },
                    // {
                    //   value: "2",
                    //   label: "موقوف",
                    // },
                    {
                      value: "3",
                      label: "محظور",
                    },
                  ]}
                />
              </Form.Item>
            </div>

            {status > 1 ? (
              <>
                {status == 2 || status == 3 ? (
                  <>
                    <div className="col-lg-4">
                      <Form.Item
                        label="تغير الحالة  "
                        rules={[
                          {
                            required: true,
                            message: "هذا الحقل مطلوب!",
                          },
                        ]}
                        name=""
                      >
                        <DatePicker
                          placeholder=""
                          className="w-100"
                          onChange={handleBlockTo}
                        />
                      </Form.Item>
                    </div>
                  </>
                ) : null}

                {status == 3 ? (
                  <>
                    <div className="col-lg-4">
                      <Form.Item
                        label="السبب"
                        rules={[
                          {
                            required: true,
                            message: "هذا الحقل مطلوب!",
                          },
                        ]}
                        name="reason"
                      >
                        <TextArea
                          rows={1}
                          name="reason"
                          onChange={handleChange}
                        />
                      </Form.Item>
                    </div>
                  </>
                ) : null}
              </>
            ) : null}
          </div>

          <div className="row">
            <div className="col-lg-4 mt-3 offset-lg-4">
              <Form.Item
                wrapperCol={{
                  offset: 1,
                  span: 16,
                }}
              >
                <Button
                  loading={loading}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  {loading ? "جاري التعديل..." : "تعديل"}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      ) : (
        <div className="text-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default ChangeStatusOwner;

// import React, { useEffect, useState } from "react";
// import {
//   Input,
//   Typography,
//   Button,
//   message,
//   Select,
//   Form,
//   DatePicker,
// } from "antd";
// import { get, postFromData } from "../../api/axios";
// import { useNavigate, useParams } from "react-router-dom";

// const { Title } = Typography;
// const { TextArea } = Input;

// const ChangeStatusOwner = () => {
//   const { ownerId } = useParams();
//   const navigate = useNavigate();
//   const [status, setStatus] = useState(null); // Initialize status as null to avoid rendering issues
//   const [inputsValue, setInputsValue] = useState({
//     reason: "",
//     user_id: "",
//     status: "",
//     block_to: "",

//   });
//   const [loading, setLoading] = useState(false);

//   // Fetch owner data and update the state after data retrieval
//   useEffect(() => {
//     if (ownerId) {
//       get(`owners/${ownerId}`)
//         .then((res) => {
//           setInputsValue((prevState) => ({
//             ...prevState,
//             user_id: ownerId,
//             status: res.data.status,
//           }));
//           setStatus(res.data.status);
//         })
//         .catch((err) => {
//           console.error("Error fetching owner data:", err);
//         });
//     }
//   }, [ownerId]);

//   const handleStatusChange = (value) => {
//     setInputsValue((prevState) => ({
//       ...prevState,
//       status: value,
//     }));
//     setStatus(value);
//   };

//   const handleChange = (e) => {
//     setInputsValue((prevState) => ({
//       ...prevState,
//       [e.target.name]: e.target.value,
//     }));
//   };

//   const handleBlockTo = (date, dateString) => {
//     setInputsValue((prevState) => ({
//       ...prevState,
//       block_to: dateString,
//     }));
//   };

//   const onFinish = () => {
//     setLoading(true);

//     postFromData("change-user-status", inputsValue)
//       .then(() => {
//         message.success("تم الإضافة بنجاح");
//         setLoading(false);
//         navigate("/owners");
//       })
//       .catch((err) => {
//         message.error(err.response.data.message || "حدث خطأ ما");
//         setLoading(false);
//       });
//   };

//   return (
//     <div>
//       <Title level={2} className="text-main mb-4">
//         تغيير حالة المالك
//       </Title>
//       <Form
//         onFinish={onFinish}
//         labelCol={{
//           span: 7,
//         }}
//         wrapperCol={{
//           span: 15,
//         }}
//         autoComplete="off"
//       >
//         <div className="row">
//           <div className="col-lg-4">
//             <Form.Item
//               label="الحالة"
//               rules={[
//                 {
//                   required: true,
//                   message: "هذا الحقل مطلوب!",
//                 },
//               ]}
//               name="status"
//             >
//               <Select
//                 className="w-100"
//                 placeholder={status}
//                 onChange={handleStatusChange}
//                 options={[
//                   {
//                     value: "1",
//                     label: "مفعل",
//                   },
//                   {
//                     value: "3",
//                     label: "محظور",
//                   },
//                 ]}
//               />
//             </Form.Item>
//           </div>

//           {status > 1 && (
//             <>
//               {(status === "2" || status === "3") && (
//                 <div className="col-lg-4">
//                   <Form.Item
//                     label="تاريخ الحظر"
//                     rules={[
//                       {
//                         required: true,
//                         message: "هذا الحقل مطلوب!",
//                       },
//                     ]}
//                     name="block_to"
//                   >
//                     <DatePicker
//                       placeholder="اختر التاريخ"
//                       className="w-100 ml-5"
//                       onChange={handleBlockTo}
//                     />
//                   </Form.Item>
//                 </div>
//               )}

//               {status === "3" && (
//                 <div className="col-lg-4">
//                   <Form.Item
//                     label="السبب"
//                     rules={[
//                       {
//                         required: true,
//                         message: "هذا الحقل مطلوب!",
//                       },
//                     ]}
//                     name="reason"
//                   >
//                     <TextArea rows={1} name="reason" onChange={handleChange} />
//                   </Form.Item>
//                 </div>
//               )}
//             </>
//           )}
//         </div>

//         <div className="row">
//           <div className="col-lg-4 mt-3 offset-lg-4">
//             <Form.Item
//               wrapperCol={{
//                 offset: 1,
//                 span: 16,
//               }}
//             >
//               <Button
//                 loading={loading}
//                 size="large"
//                 block
//                 type="primary"
//                 htmlType="submit"
//               >
//                 {loading ? "جاري التعديل..." : "تعديل"}
//               </Button>
//             </Form.Item>
//           </div>
//         </div>
//       </Form>
//     </div>
//   );
// };

// export default ChangeStatusOwner;
