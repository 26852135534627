import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { postFromData } from "../../api/axios";

const Login = () => {
  const [loading, setLoading] = useState(false);

  const onFinish = values => {
    setLoading(true);

    postFromData("login", loginData)
      .then(res => {
        message.success("تم تسجيل الدخول بنجاح!");
        localStorage.setItem("tokenAdmin", res.data.token);
        localStorage.setItem("authNameAdmin", res.data.name);
         localStorage.setItem("loggedInAdmin", true);
        localStorage.setItem(
          "permissionsAdmin",
          JSON.stringify(res.data.permissions)
        );
        window.location.href = "/";
      })
      .catch(err => {
        message.error("البريد الإلكتروني او كلمة السر غير صحيح");
        setLoading(false);
      });
  };

  const onFinishFailed = errorInfo => {
    console.log("Failed:", errorInfo);
  };

  const validateUsername = (_, value) => {
    if (!value) {
      return Promise.reject("Please enter your username");
    } else {
      return Promise.resolve();
    }
  };

  const validatePassword = (_, value) => {
    if (!value) {
      return Promise.reject("Please enter your password");
    } else {
      return Promise.resolve();
    }
  };

  const [loginData, setloginData] = useState({ email: "", password: "" });
  const handleChange = e => {
    setloginData({ ...loginData, [e.target.id]: e.target.value });
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}> تسجيل الدخول </h2>
      <Form
        style={styles.form}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label="البريد الإلكتروني"
          name="email"
          rules={[{ validator: validateUsername }]}
          onChange={handleChange}
        >
          <Input prefix={<UserOutlined style={styles.icon} />} />
        </Form.Item>
        <Form.Item
          label="كلمة السر"
          name="password"
          rules={[{ validator: validatePassword }]}
          onChange={handleChange}
        >
          <Input.Password prefix={<LockOutlined style={styles.icon} />} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" block htmlType="submit" loading={loading}>
            {loading ? "جاري الدخول..." : "دخول"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    flexDirection: "column",
  },
  title: {
    textAlign: "center",
    marginBottom: 24,
  },
  form: {
    width: 300,
    // margin: 'auto'
  },
  icon: {
    color: "rgba(0, 0, 0, 0.25)",
  },
};

export default Login;
