import React, { useEffect, useState } from "react";
import {
  Input,
  Typography,
  Button,
  message,
  Select,
  Spin,
  Form,
  DatePicker,
} from "antd";
import { get, put, putFromData } from "../../api/axios";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import { TagsInput } from "react-tag-input-component";
import dayjs from "dayjs";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
const { Title } = Typography;
const dateFormat = "YYYY/MM/DD";

const EditMarketer = () => {
  const navigate = useNavigate();
  let { marketerId } = useParams();

  const [fromloading, setFromLoading] = useState(false);

  const [inputsValue, setinputsValue] = useState({ properties: [] });
  
  const handleChange = (e) => {
    setinputsValue({ ...inputsValue, [e.target.id]: e.target.value });
  };

  useEffect(() => {
    get(`marketers/${marketerId}`).then((res) => {
      setinputsValue(res.data);
      setFromLoading(true);
    });
  }, []);

  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    
    const processedValues = Object.fromEntries(
      Object.entries(values).map(([key, value]) => [key, value ?? ""])
    );
  
    let payload = {
      ...processedValues,
       added_properties: selected,
      contact_end_date:null,
       contact_start_date: values.contact_start_date.format("YYYY-MM-DD"),
      dob: values.dob.format("YYYY-MM-DD"),
      graduation_date: values.graduation_date.format("YYYY-MM-DD"),
    };
  
    if (values.contact_end_date && values.contact_end_date !== "null") {
      payload.contact_end_date = values.contact_end_date.format("YYYY-MM-DD");
    } else {
      delete payload.contact_end_date;
  
    }
  
  
    putFromData(`marketers/${marketerId}`, payload)
      .then((res) => {
        message.success("تم التعديل بنجاح");
        setLoading(false);
        navigate("/marketers");
      })
      .catch((err) => {
        message.error(err.response?.data?.message || "حدث خطأ ما");
        setLoading(false);
      });
  };
  
  const [dataCities, setDataCities] = useState([]);
  const [option, setOption] = useState([]);

  useEffect(() => {
    setOption(
      dataCities.map((d) => ({
        value: d.id,
        label: d.name_ar,
      }))
    );
  }, [dataCities]);

  useEffect(() => {
    get("get-all-cities").then((res) => {
      setDataCities(res.data);
    });
  }, []);

  const [dataNationality, setDataNationality] = useState([]);
  const [optionNationality, setOptionNationality] = useState([]);
  const [optionCountries, setOptionCountries] = useState([]);

  useEffect(() => {
    setOptionNationality(
      dataNationality.map((d) => ({
        value: d.id,
        label: d.nationality_ar,
      }))
    );
    setOptionCountries(
      dataNationality.map((d) => ({
        value: d.id,
        label: d.name_ar,
      }))
    );
  }, [dataNationality]);

  const [dataResidence_type, setDataResidence_type] = useState([]);
  const [optionResidence_type, setOptionResidence_type] = useState([]);

  useEffect(() => {
    setOptionResidence_type(
      dataResidence_type.map((d) => ({
        value: d.id,
        label: d.name,
      }))
    );
  }, [dataResidence_type]);

  const [dataQualification, setDataQualification] = useState([]);
  const [optionQualification, setOptionQualification] = useState([]);
  useEffect(() => {
    setOptionQualification(
      dataQualification.map((d) => ({
        value: d.id,
        label: d.name,
      }))
    );
  }, [dataQualification]);

  const [dataPay_type, setDataPay_type] = useState([]);
  const [optionPay_type, setOptionPay_type] = useState([]);
  useEffect(() => {
    setOptionPay_type(
      dataPay_type.map((d) => ({
        value: d.id,
        label: d.name,
      }))
    );
  }, [dataPay_type]);

  const [dataRole_id, setDataRole_id] = useState([]);
  const [optionRole_id, setOptionRole_id] = useState([]);
  useEffect(() => {
    setOptionRole_id(
      dataRole_id.map((d) => ({
        value: d.id,
        label: d.name_ar,
      }))
    );
  }, [dataRole_id]);

  useEffect(() => {
    get("get-all-nationalities").then((res) => {
      setDataNationality(res.data);
    });
    get("get-all-residence-types").then((res) => {
      setDataResidence_type(res.data);
    });
    get("get-all-qualifications").then((res) => {
      setDataQualification(res.data);
    });
    get("get-all-pay-types").then((res) => {
      setDataPay_type(res.data);
    });
    get("get-all-roles").then((res) => {
      setDataRole_id(res.data.data);
    });
  }, []);

  const onChange = (value) => {
     setinputsValue({ ...inputsValue, city_id: value });
  };
  const onSearch = (value) => {
   };

  const [selected, setSelected] = useState([]);
  const blockedProperties = (items) => {
    setSelected(items);
    // setinputsValue({ ...inputsValue, added_properties: items })
   };

  // const [propertiesId, setPropertiesId] = useState([]);
  useEffect(() => {
     const propertiesList = [];
    inputsValue.properties.map((item) => {
       propertiesList.push(item.id);
    });
    setSelected(propertiesList);
  }, [inputsValue]);

  return (
    <div>
      <Title level={2} className="text-main mb-4">
        {" "}
        تعديل المسوق{" "}
      </Title>
      {fromloading ? (
        <Form
          onFinish={onFinish}
          labelCol={{
            span: 10,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            name: inputsValue.name,
            email: inputsValue.email,
            address: inputsValue.address,
            gender: inputsValue.gender == "ذكر" ? "1" : "2",
            pay_type: inputsValue.pay_type,
            role_id: inputsValue.role_id,
            marital_status: inputsValue.marital_status == "أعزب" ? "1" : "2",
            employment_status:
              inputsValue.employment_status == "على رأس العمل" ? "1" : "2",
            qualification: inputsValue.qualification,
            rate: inputsValue?.rate?.value,
            specialization: inputsValue.specialization,
            source_of_certificate: inputsValue.source_of_certificate,
            graduation_date: dayjs(inputsValue.graduation_date),
            instagram_url: inputsValue.instagram_url,
            twitter_url: inputsValue.twitter_url,
            facebook_url: inputsValue.facebook_url,
            added_properties: inputsValue.properties,
            country_of_certificate: inputsValue.country_of_certificate.id,
            phone: inputsValue.phone,
            residence_type: inputsValue.residence_type,
            dob:  dayjs(inputsValue?.dob),
            contact_start_date:  dayjs(inputsValue?.contact_start_date),
            contact_end_date:inputsValue?.contact_end_date?  dayjs(inputsValue?.contact_end_date): null,
            nationality_id: inputsValue.nationality_id,
            id_number: inputsValue.id_number, 
 
          }}
          autoComplete="off"
        >
          <div className="row">
          <div className="col-lg-4">
            <Form.Item
                label="الاسم"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Input/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="البريد الإلكتروني"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Input/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
          <Form.Item
  label="الجوال"
  name="phone"
  rules={[
    {
      required: true,
      message: "هذا الحقل مطلوب!",
    },
    {
      min: 10,
      message: "يجب ان يكون اكتر من 10 ارقام",
    },
  ]}
>
  <PhoneInput
    onChange={(value) => 
      setinputsValue((prev) => ({
        ...prev,
        phone:  value ? value.replace("+", "") : "" 
      }))
    }
    value={inputsValue.phone || ""}  
  />
 
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="العنوان"
                name="address"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Input/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="النوع"
                name="gender"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد النوع"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                      (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "1",
                      label: "ذكر",
                    },
                    {
                      value: "2",
                      label: "مؤنث",
                    },
                  ]}
              />
            </Form.Item>
          </div>
          <div className="col-lg-4">
              <Form.Item
                label="الحالة الاجتماعية"
                name="marital_status"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد الحالة الاجتماعية"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "1",
                      label: "اعزب",
                    },
                    {
                      value: "2",
                      label: "متزوج",
                    },
                  ]}
                />
              </Form.Item>
            </div>

          <div className="col-lg-4">
            <Form.Item
                label="التقدير "
                name="rate"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد  التقدير"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                      (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: 0,
                      label: " غير مقيم ",
                    },
                    {
                      value: 1,
                      label: "ضعيف",
                    },
                    {
                      value: 2,
                      label: "مقبول",
                    },
                    {
                      value: 3,
                      label: "جيد",
                    },
                    {
                      value: 4,
                      label: " جيدجداً",
                    },
                    {
                      value: 5,
                      label: "   ممتاز",
                    },
                  ]}
              />
            </Form.Item>
          </div>

          <div className="col-lg-4">
            <Form.Item
                label="الجنسية"
                name="nationality_id"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد الجنسية"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                      (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                  }
                  options={optionNationality}
              />
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="دولة المؤهل"
                name="country_of_certificate"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Select
    className="w-100"
    showSearch
    placeholder="حدد دولة المؤهل"
    optionFilterProp="children"
    onSearch={onSearch}
    filterOption={(input, option) =>
                      (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                  }
    options={optionCountries}
/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="نوع الإقامة"
                name="residence_type"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد نوع الإقامة"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                      (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                  }
                  options={optionResidence_type}
              />
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="المؤهل"
                name="qualification"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد المؤهل"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                      (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                  }
                  options={optionQualification}
              />
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="نوع الدفع"
                name="pay_type"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد نوع الدفع"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                      (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                  }
                  options={optionPay_type}
              />
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="الأذونات"
                name="role_id"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد الأذونات"
                  optionFilterProp="children"
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                      (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                  }
                  options={optionRole_id}
              />
            </Form.Item>
          </div>

          <div className="col-lg-4">
            <Form.Item
                label="رقم الاثبات  "
                name="id_number"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Input/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="التخصص"
                name="specialization"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Input/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="مصدر الشهادة"
                name="source_of_certificate"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <Input/>
            </Form.Item>
          </div>

          <div className="col-lg-4">
            <Form.Item
                label="تاريخ الميلاد"
                name="dob"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <DatePicker placeholder="" className="w-100"/>
            </Form.Item>
          </div>

          <div className="col-lg-4">
            <Form.Item
                label="تاريخ التخرج"
                name="graduation_date"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <DatePicker placeholder="" className="w-100"/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="تاريخ التعاقد   "
                name="contact_start_date"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
            >
              <DatePicker placeholder="" className="w-100"/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="     تاريخ انتهاء التعاقد"
                name="contact_end_date"
               
             >
              <DatePicker placeholder="" className="w-100"/>
            </Form.Item>
          </div>

          <div className="col-lg-4">
            <Form.Item
                label="الانستغرام"
                name="instagram_url"
                 
            >
              <Input/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="تويتر"
                name="twitter_url"
                
            >
              <Input/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="فيسبوك"
                name="facebook_url"
                 
            >
              <Input/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
              <Form.Item
                label="حالة التوظيف"
                name="employment_status"
                rules={[
                  {
                    required: true,
                    message: "هذا الحقل مطلوب!",
                  },
                ]}
              >
                <Select
                  className="w-100"
                  showSearch
                  placeholder="حدد حالة التوظيف"
                  optionFilterProp="children"
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: "1",
                      label: "علي رأس العمل",
                    },
                    {
                      value: "2",
                      label: "إنهاء التعاقد",
                    },
                  ]}
                />
              </Form.Item>
            </div>
          <div className="col-lg-4">
            <Form.Item label="كلمة السر" name="password">
              <Input.Password/>
            </Form.Item>
          </div>
          <div className="col-lg-4">
            <Form.Item
                label="العقارات"
                name="added_properties"
                 
            >
              <div>
                <TagsInput
                    value={selected}
                    onChange={blockedProperties}
                    // // name="blocked_properties"
                />
                <small>لاضافة عقار اضغظ Enter بعد الكتابة</small>
              </div>
            </Form.Item>
          </div>

        

                    <div className="col-lg-6 offset-lg-3">
              <Form.Item
                wrapperCol={{
                  offset: 1,
                  span: 16,
                }}
              >
                <Button
                  loading={loading}
                  size="large"
                  block
                  type="primary"
                  htmlType="submit"
                >
                  {loading ? "جاري التعديل..." : "تعديل"}
                </Button>
              </Form.Item>
            </div>
        </div>
        </Form>
      ) : (
        <div className="text-center">
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export default EditMarketer;
