import React, { useEffect, useState } from 'react'
import { Input, Typography, Button, message, Select, Spin, Form, } from 'antd';
import { get, put } from '../../api/axios';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';

const { Title } = Typography;

const EditRole = () => {
    const navigate = useNavigate();
    let { Id } = useParams();


    const [fromloading, setFromLoading] = useState(false);

    const [inputsValue, setinputsValue] = useState({ "name_ar": '', "name": "", "permissions": "" });
    const handleChange = (e) => {
        setinputsValue({ ...inputsValue, [e.target.id]: e.target.value })
    }

    useEffect(() => {
        get(`roles/${Id}`).then(res => {
            console.log('djkl', res.data.permissions);
            const newArray = []
            res.data.permissions.forEach(function (arrayItem) {
                newArray.push(arrayItem.value)
            });
            console.log('arrayItem', newArray);
            setinputsValue({...res.data, 'permissions': newArray})
            setFromLoading(true)
        })
    }, []);


    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setLoading(true);

        console.log('values', values);

        put(`roles/${Id}`, inputsValue).then(res => {
            message.success('تم تعديل بنجاح');
            setLoading(false);
            navigate('/roles')
        }).catch(err => {
            message.error(err.response.data.message);
            setLoading(false);
        })

    };


    const [dataPermissions, setDataPermissions] = useState([]);
    const [optionPermissions, setOptionPermissions] = useState([]);

    useEffect(() => {
        setOptionPermissions(dataPermissions.map(d => ({
            "value": d.value,
            "label": d.display_name,
        })))

    }, [dataPermissions]);

    useEffect(() => {
        get('get-all-permissions').then(res => {
            setDataPermissions(res.data)
        })
    }, []);

    const onChange = (value) => {
        setinputsValue({ ...inputsValue, permissions: value })
    };
    const onSearch = (value) => {
        console.log('search:', value);
    }; 

    return (
        <div>
            <Title level={2} className='text-main mb-4'> تعديل الدور  </Title>
            {
                fromloading ?
                    <Form
                        onFinish={onFinish}
                        labelCol={{
                            span: 8,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}

                        initialValues={{
                            "name_ar": inputsValue.name_ar,
                            "name": inputsValue.name,
                            "permissions": inputsValue.permissions
                        }}
                        autoComplete="off"
                    >
                        <div className='row'>


                            <div className='col-lg-6'>

                                <Form.Item
                                    label='الاسم بالعربي'
                                    name='name_ar'
                                    onChange={handleChange}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'هذا الحقل مطلوب!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </div>
                            <div className='col-lg-6'>

                                <Form.Item
                                    label='الاسم بالانجليزي'
                                    name='name'
                                    onChange={handleChange}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'هذا الحقل مطلوب!',
                                        },
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                            </div>
                            <div className='col-lg-8'>
                                <Form.Item
                                    label='الأذونات'
                                    name='permissions'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'هذا الحقل مطلوب!',
                                        },
                                    ]}
                                >

                                    <Select
                                        mode='multiple'
                                        className='w-100'
                                        showSearch
                                        placeholder="حدد الأذونات"
                                        optionFilterProp="children"
                                        onChange={onChange}
                                        onSearch={onSearch}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={optionPermissions}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'هذا الحقل مطلوب!',
                                            },
                                        ]}
                                    />
                                </Form.Item>
                            </div>

                            <div className='col-lg-4 offset-lg-4'>

                                <Form.Item
                                    wrapperCol={{
                                        offset: 1,
                                        span: 16,
                                    }}
                                >
                                    <Button
                                        loading={loading}
                                        size='large' block type="primary" htmlType="submit">
                                        {loading ? 'جاري التعديل...' : 'تعديل'}
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>



                    </Form>

                    :
                    <div className='text-center'>
                        <Spin size="large" />
                    </div>
            }
        </div>
    )
}

export default EditRole
